import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useCSVReader } from "react-papaparse";
import BuyCredit from "../../components/Enrichment/BuyCredit";
import { IoIosArrowDown } from "react-icons/io";
import { GiShare } from "react-icons/gi";
import { BsFillPersonFill } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/dist/svg-arrow.css";
import { json2csv } from "json-2-csv";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { startANewEnrichment } from "../../redux/actions/enrichmentAction";
import EnrichmentModal from "../../components/Enrichment/EnrichmentModal";
import { MdClear } from "react-icons/md";
export const UploadACSV = (props) => {
  const {
    handleOpenCSVReader,
    handleDeleteUploaded,
    result,
    setResult,
    fileName,
    setFileName,
    startANewEnrichment,
  } = props;

  const { CSVReader } = useCSVReader();
  const [buyModalStatus, setBuyModalStatus] = useState(false);
  const [csvMapIndex, setCsvMapIndex] = useState({
    website: null,
    linkedin_url: null,
    first_name: null,
    last_name: null,
  });
  const [selectedDropdownId, setSelectedDropdownId] = useState(null);
  const [startEnrichLoading, setStartEnrichLoading] = useState(false);

  const [enrichmentName, setEnrichmentName] = useState("");
  const [noOfRows, setNoOfRows] = useState(result ? result.length : 0);

  const navigate = useNavigate();

  const isSimilar = (str1, str2) => {
    // Convert both strings to lowercase for a case-insensitive comparison
    str1 = str1.toLowerCase();
    str2 = str2.toLowerCase();

    // Check for direct matches
    if (str1 === str2) return true;

    // Check for common variations
    const variations = {
      website: [
        "web",
        "site",
        "homepage",
        "domain",
        "url",
        "organization_primary_domain",
        "website",
        "link",
      ],
      email: ["e-mail", "mail", "emailaddress", "contactmail", "email"],
      company: ["firm", "business", "corp", "corporation", "enterprise"],
      first_name: [
        "firstname",
        "fname",
        "givenname",
        "namefirst",
        "name",
        "first name",
      ],
      last_name: [
        "lastname",
        "lname",
        "surname",
        "namelast",
        "familyname",
        "last name",
      ],
      title: ["designation", "position", "role", "jobtitle", "job"],
      seniority: ["rank", "status", "level"],
      department: ["dept", "section", "division", "unit"],
      phone: ["phonenumber", "mobile", "tel", "cell", "contactnumber"],
      number_of_employees: ["employee_count", "staffcount", "teamsize"],
      industry: ["sector", "field", "businessarea", "market"],
      facebook_url: [
        "facebook",
        "fb",
        "facebookpage",
        "fb_url",
        "facebook url",
      ],
      instagram_url: [
        "instagram",
        "insta",
        "ig",
        "instagrampage",
        "ig_url",
        "instagram url",
      ],
      tiktok_url: ["tiktok", "tt", "tiktokpage", "tt_url", "tiktok url"],
      linkedin_url: [
        "linkedin",
        "li",
        "linkedinpage",
        "li_url",
        "company linkedin url",
      ],
      personal_linkedin_url: [
        "personal_linkedin",
        "personal_li",
        "private_li",
        "personal_li_url",
        "person linkedin url",
      ],
      twitter_url: ["twitter", "tw", "twitterpage", "tw_url", "twitter url"],
      city: ["location_city", "town", "urban", "city"],
      state: ["province", "region", "location_state", "territory", "state"],
      country: ["nation", "location_country", "country"],
      description: ["desc", "about", "info", "summary", "overview"],
    };

    for (let key in variations) {
      if (
        (key === str1.toLowerCase() &&
          variations[key].includes(str2.toLowerCase().trim())) ||
        (key === str2.toLowerCase() &&
          variations[key].includes(str1.toLowerCase().trim()))
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (result) {
      setNoOfRows(result.length - 1);
    }
  }, [result]);

  useEffect(() => {
    if (result?.length > 0) {
      // console.log(result, "testing");
      var array = result[0];
      var data = result[1];
      console.log(data);
      console.log(result, "testing");
      const newCsvMapIndex = { ...csvMapIndex };
      for (let i = 0; i < array.length; i++) {
        for (let key in newCsvMapIndex) {
          if (isSimilar(key, array[i])) {
            newCsvMapIndex[key] = i;
            break;
          }
        }
      }

      console.log(newCsvMapIndex, "newCsvMapIndex");

      let domainMapped = newCsvMapIndex.website;
      let companyMapped = newCsvMapIndex.company;
      let linkedInMapped = newCsvMapIndex.linkedin_url;

      if (result.length > 1) {
        if (linkedInMapped == 0 || linkedInMapped) {
          let val;
          if (domainMapped == 0 || domainMapped) {
            function normalizeDomain(domain) {
              return domain
                .replace(/^https?:\/\//, "") // Remove http:// or https://
                .replace(/^www\./, "") // Remove www.
                .replace(/\/$/, "")
                .replace(/com/, "")
                .replace(".", "");
            }
            val = normalizeDomain(data[domainMapped]);
          }
          if (companyMapped == 0 || companyMapped) {
            val = data[companyMapped];
          }
          // console.log(val, "val");
          let linkedInVal = data[linkedInMapped];
          if (val && linkedInVal.includes(val)) {
            newCsvMapIndex.linkedin_url = null;
          }
        }
      }

      setCsvMapIndex(newCsvMapIndex);
    }
  }, [result]);

  const handleCancel = () => {
    setBuyModalStatus(false);
  };

  const resetUpload = () => {
    setResult(null);
    setFileName(null);
    setCsvMapIndex({
      website: null,

      // company: null,
      first_name: null,
      last_name: null,

      linkedin_url: null,
    });
  };

  var mappingArray = [
    {
      label: "Website",
      value: "website",
    },
    {
      label: "First Name",
      value: "first_name",
    },
    {
      label: "Last Name",
      value: "last_name",
    },
    // {
    //   label: "Company",
    //   value: "company",
    // },
    {
      label: "Linkedin URL",
      value: "linkedin_url",
    },
  ];

  React.useEffect(() => {
    const paragraphs = document.querySelectorAll(".fade-in");
    paragraphs.forEach((p) => {
      p.classList.add("animate-fade-in");
    });
  }, []);

  return (
    <div className="w-1/2 px-5 py-3 fade-in border-[1px] border-[#EAECF0] rounded-lg">
      <div className="flex item-center justify-between">
        <div className="">
          <p className="text-lg fade-in text-secondary font-semibold text-Inter">
            Upload a CSV
          </p>
          <p className="text-sm fade-in text-secondarySupport text-Inter">
            Upload a CSV file to enrich it with more fields.
          </p>
        </div>
      </div>

      <CSVReader onUploadAccepted={handleOpenCSVReader}>
        {({ getRootProps, acceptedFile, getRemoveFileProps }) => (
          <>
            {result && result.length > 0 ? (
              <>
                <p className="text-sm mt-3 fade-in text-secondarySupport text-Inter">
                  Fill all contact fields for maximum results. LinkedIn searches
                  without names will cost 2x credits.
                </p>
                <form
                  autoComplete="off"
                  className="mx-auto max-w-md w-full fade-in rounded-lg border-[2px] mb-10 border-[#AFAFAF] border-opacity-30  py-3 mt-7"
                >
                  {mappingArray.map((it, key) => {
                    // console.log(it, key);
                    return (
                      <div>
                        {key == 0 && (
                          <div className="px-5">
                            <p className="font-Inter font-semibold text-gray-600 text-sm">
                              Person Search
                            </p>
                          </div>
                        )}

                        {key == 1 && (
                          <div className="px-5 mt-4 flex item-center gap-x-1">
                            {/* <BsFillPersonFill className="text-gray-600 h-5 w-5" /> */}
                            <p className="font-Inter font-semibold text-gray-600 text-md">
                              Contact
                            </p>
                            <hr className="bg-gray-200 h-[1.15px] mt-3" />
                          </div>
                        )}
                        <div
                          className={`flex justify-start items-center py-3 px-5 border-b-[0.5px] border-[#4B5563] border-opacity-10 `}
                        >
                          <p className="font-Inter w-5/12 text-[#101828] font-semibold text-sm ">
                            {it.label}
                            {key < 1 && (
                              <span className="text-red-600"> *</span>
                            )}
                          </p>

                          <div className="relative  w-7/12">
                            <button
                              type="button"
                              onClick={() => {
                                // document
                                //   .getElementById(`map_${it.value}_dropdown`)
                                //   .classList.toggle("hidden");
                                if (selectedDropdownId) {
                                  if (
                                    selectedDropdownId ===
                                    `map_${it.value}_dropdown`
                                  ) {
                                    setSelectedDropdownId(null);
                                  } else {
                                    setSelectedDropdownId(
                                      `map_${it.value}_dropdown`
                                    );
                                  }
                                } else {
                                  setSelectedDropdownId(
                                    `map_${it.value}_dropdown`
                                  );
                                }
                              }}
                              className="flex flex-row w-full items-center justify-between rounded-lg bg-white p-2 ring-1 ring-gray-300"
                            >
                              <span className="text-Inter text-sm font-[400] text-slate-600 px-1">
                                {result[0][csvMapIndex[it.value]]
                                  ? result[0][csvMapIndex[it.value]]
                                  : `Please select a field`}
                              </span>
                              <div className="flex flex-row gap-1">
                                <span className="text-2xl w-5 h-5 grid place-content-center">
                                  <IoIosArrowDown className="w-5" />
                                </span>
                                {(csvMapIndex[it.value] == 0 ||
                                  csvMapIndex[it.value]) && (
                                  <span className="text-2xl w-5 h-5 grid place-content-center">
                                    <MdClear
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setCsvMapIndex({
                                          ...csvMapIndex,
                                          [it.value]: null,
                                        });
                                        setSelectedDropdownId(null);
                                      }}
                                      className="w-5"
                                    />
                                  </span>
                                )}
                              </div>
                            </button>

                            {selectedDropdownId ===
                              `map_${it.value}_dropdown` && (
                              <ul
                                id={`map_${it.value}_dropdown`}
                                className="z-20 absolute mt-2 w-full h-fit rounded bg-white ring-1 ring-gray-300 max-h-40 overflow-y-auto scroll-smooth"
                              >
                                <li className="cursor-pointer text-Inter text-xs font-[400] bg-gray-200 select-none px-2 py-1.5 hover:bg-gray-200">
                                  Please select a field
                                </li>
                                {result[0].map((item, index) => (
                                  <li
                                    key={index}
                                    onClick={(e) => {
                                      setCsvMapIndex({
                                        ...csvMapIndex,
                                        [it.value]: Number(index),
                                      });
                                      if (selectedDropdownId) {
                                        setSelectedDropdownId(null);
                                      } else {
                                        setSelectedDropdownId(
                                          `map_${it.value}_dropdown`
                                        );
                                      }
                                      // document
                                      //   .getElementById(`map_${it.value}_dropdown`)
                                      //   .classList.toggle("hidden");
                                    }}
                                    className="cursor-pointer text-Inter text-sm font-[400] select-none p-2 hover:bg-slate-100"
                                  >
                                    {item}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </form>
              </>
            ) : (
              <div
                {...getRootProps()}
                className="flex items-center justify-center w-full py-3"
              >
                <label
                  for="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-52 border-2 border-[#FCFCFD] border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 hover:bg-gray-100"
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      width="40"
                      height="40"
                      className="bg-[#F9FAFB] rounded-full p-2.5"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.66666 12.3333L10 9M10 9L13.3333 12.3333M10 9V16.5M16.6667 12.9524C17.6846 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2184 2.73736 10.212 1.5 7.91666 1.5C4.46488 1.5 1.66666 4.29822 1.66666 7.75C1.66666 9.47175 2.36287 11.0309 3.48912 12.1613"
                        stroke="#475467"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <>
                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold text-primary">
                          Click to upload
                        </span>{" "}
                        or drag and drop
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        CSV (MAX. 25MB)
                      </p>
                    </>
                  </div>
                </label>
              </div>
            )}
          </>
        )}
      </CSVReader>

      {result && result.length > 0 && (
        <div className="flex items-center justify-between px-4">
          <button
            onClick={() => {
              resetUpload();
            }}
            className="px-4 py-2 text-sm text-Inter font-[500] rounded-lg border-[1px] border-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setBuyModalStatus(true);
            }}
            className={`px-4 py-2 text-sm text-Inter ${
              !(csvMapIndex.website !== null) && `bg-opacity-70`
            } bg-primary text-white font-[500] rounded-lg border-[1px] cursor-pointer`}
            disabled={!(csvMapIndex.website !== null)}
          >
            {startEnrichLoading ? "Loading..." : "Continue"}
          </button>
        </div>
      )}

      {buyModalStatus && (
        <EnrichmentModal
          result={result}
          fileName={fileName}
          handleCancel={handleCancel}
          csvMapIndex={csvMapIndex}
          mappingArray={mappingArray}
        />
      )}
      {/* {
                confirmationModalStatus &&
                <ConfirmationModal
                    heading={'Are you sure?'}
                    message={'You are about to a new enrichment'}
                    onCancel={onCancel}
                    onConfirm={onConfirm} />
            } */}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { startANewEnrichment };

export default connect(mapStateToProps, mapDispatchToProps)(UploadACSV);
